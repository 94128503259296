<template>
  <v-container fluid>
    <v-row>
      <v-col v-for='(item, index) in list' :key='index' sm='12' md='2'>
        <v-card flat class='wd-100'>
          <v-col cols='12'>
            <v-row no-gutters justify='center'>
              <v-img contain max-width='75' max-height='75' :src='require("../../assets/pc.svg")' />
            </v-row>
          </v-col>
          <v-col class='pa-0' cols='12' justify='center'>
            <v-row class='pa-0 text-muted' no-gutters justify='center'>
              {{ $t('DEVICE_NAME') }}
            </v-row>
          </v-col>
          <v-col class='pa-0' cols='12' justify='center'>
            <v-row class='pa-0' no-gutters justify='center'>
              <span class='color-primary'>{{ item.hwHostname }}</span>
            </v-row>
          </v-col>
          <v-col class='pa-0' cols='12' justify='center'>
            <v-row class='text-muted' no-gutters justify='center'>
              {{ formatDate(item.createdAt, 'dd/MM/yyyy HH:mm:ss') }}
            </v-row>
          </v-col>
          <v-card-actions>
            <v-row no-gutters justify='center'>
              <v-btn color='error' outlined @click='revokeLicense(item)'>{{ $t('REVOKE_LICENSE') }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  import { mapGetters } from 'vuex';
  import { format } from 'date-fns';

  export default {
    name: 'LicenseManagerItems',
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    methods: {
      revokeLicense: async function (app) {
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: `Deseja realmente revogar a licença da maquina ${app.hwHostname} esta licença?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.value) {
            await axios({
              url: `/customer-application-info/${this.getUser.account.customerId}/${app.id}`,
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'DELETE',
              responseType: 'json',
              withCredentials: true,
            });
            this.$emit('reload');
          }
        });
      },
      formatDate: function (date, formatString) {
        return format(new Date(date), formatString);
      },
    },
  };
</script>
